import React from 'react'

const Dashboard = () => {
  return (
    <>
      <div>
        <h2 className='text-center mt-3'>Welcome To RBL BANK Admin Panel</h2>
      </div>
    </>
  )
}

export default Dashboard